// extracted by mini-css-extract-plugin
export var backgroundGradient = "Section-module--backgroundGradient--ac836";
export var backgroundGradientHeader = "Section-module--backgroundGradientHeader--1abc4";
export var backgroundGradientWhite = "Section-module--backgroundGradientWhite--9edb6";
export var backgroundImage = "Section-module--backgroundImage--937bc";
export var backgroundImageSection = "Section-module--backgroundImageSection--7f97e";
export var backgroundImageWrapper = "Section-module--backgroundImageWrapper--208a6";
export var backgroundVideo = "Section-module--backgroundVideo--fe22a";
export var flexFix = "Section-module--flexFix--c3664";
export var fullHeight = "Section-module--fullHeight--f0e0c";
export var fullHeightDesktop = "Section-module--fullHeightDesktop--83b0e";
export var header = "Section-module--header--ad677";
export var headerBlog = "Section-module--headerBlog--4643a";
export var hero = "Section-module--hero--7de2c";
export var heroContent = "Section-module--heroContent--c294a";
export var inner = "Section-module--inner--76c37";
export var innerMarginBottom = "Section-module--innerMarginBottom--40d77";
export var mobileBackgroundImage = "Section-module--mobileBackgroundImage--a46ca";
export var mobileBackgroundVideo = "Section-module--mobileBackgroundVideo--871a4";
export var mobileNoBackgroundImage = "Section-module--mobileNoBackgroundImage--9330b";
export var paddingBottom = "Section-module--paddingBottom--a02de";
export var paddingTop = "Section-module--paddingTop--9eb35";
export var roundedBottomRight = "Section-module--roundedBottomRight--2ed5b";
export var section = "Section-module--section--d8c31";
export var showcase = "Section-module--showcase--4c519";
export var spaceBetween = "Section-module--spaceBetween--f2bf1";
export var sticky = "Section-module--sticky--3cc60";
export var stickyInner = "Section-module--stickyInner--6a56c";
export var verticalCenter = "Section-module--verticalCenter--fe68a";
export var verticalEnd = "Section-module--verticalEnd--b0368";